import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query GetArticles(
    $pagination: PaginationArg
    $sort: [String]
    $filters: ArticleFiltersInput
  ) {
    articles(pagination: $pagination, sort: $sort, filters: $filters) {
      meta {
        pagination {
          page
          total
          pageCount
          pageSize
        }
      }
      data {
        attributes {
          description
          title
          content
          slug
          createdAt
          publishedAt
          author {
            data {
              attributes {
                name
                picture {
                  data {
                    attributes {
                      width
                      height
                      url
                      blurhash
                    }
                  }
                }
              }
            }
          }
          categories {
            data {
              attributes {
                name
                slug
              }
            }
          }
          image {
            data {
              attributes {
                width
                height
                url
                previewUrl
                blurhash
              }
            }
          }
        }
      }
    }
  }
`;
