import Head from 'next/head';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import { GlobalContext } from '../../pages/_app';

const Seo = ({ seo }) => {
  const router = useRouter();

  const domain =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';
  let path = domain + router.asPath;

  let data;

  data = useContext(GlobalContext);

  let defaultSeo = data.defaultSeo;
  let siteName = data.siteName;
  let seoWithDefaults;

  if (data) {
    seoWithDefaults = {
      ...defaultSeo,
      ...seo,
    };
  }
  const fullSeo = {
    ...seoWithDefaults,
    // Add title suffix
    metaTitle: `${seoWithDefaults.metaTitle} | ${siteName}`,
    // Get full image URL
    // shareImage: getStrapiMedia(seoWithDefaults.shareImage),
    createdAt: `${seoWithDefaults.createdAt}`,
    url: path,
  };

  return (
    <Head>
      {fullSeo.metaTitle && (
        <>
          <title>{fullSeo.metaTitle}</title>
          <meta property="og:title" content={fullSeo.metaTitle} />
          <meta name="twitter:title" content={fullSeo.metaTitle} />
          <meta property="og:site_name" content={fullSeo.metaTitle} />
        </>
      )}
      {fullSeo.metaDescription && (
        <>
          <meta name="description" content={fullSeo.metaDescription} />
          <meta property="og:description" content={fullSeo.metaDescription} />
          <meta name="twitter:description" content={fullSeo.metaDescription} />
        </>
      )}
      {fullSeo.shareImage && (
        <>
          <meta property="og:image" content={fullSeo.shareImage} />
          <meta property="twitter:image" content={fullSeo.shareImage} />
          <meta name="image" content={fullSeo.shareImage} />
          <meta property="twitter:image:width" content="1200" />
          <meta property="twitter:image:height" content="1200" />
        </>
      )}
      {fullSeo.article && <meta property="og:type" content="article" />}
      {fullSeo.createdAt && (
        <>
          <meta property="og:publish_date" content={fullSeo.createdAt} />
          <meta property="article:published_time" content={fullSeo.createdAt} />
        </>
      )}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@AndersPink" />

      <meta property="og:locale" content="en_GB" />

      <meta property="og:url" content={fullSeo.url} />
      <link rel="canonical" href={fullSeo.url}></link>
    </Head>
  );
};

export default Seo;
