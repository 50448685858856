import React from 'react';
import {
  Flex,
  Box,
  Text,
  Icon,
  useMediaQuery,
  Wrap,
  WrapItem,
  Image,
} from '@chakra-ui/react';
import { GoPrimitiveDot } from 'react-icons/go';
import moment from 'moment';
import { getStrapiMedia } from '../lib/media';
import { getDurationInSeconds } from '../lib/reader';
import { trucateString } from '../lib/trucateString';
import { capitalizeString } from '../lib/capitaliseString';
import Link from 'next/link';

const btoa = function (str) {
  return Buffer.from(str).toString('base64');
};

const NewCard = ({ article, isBig }) => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');

  let isLarger = isLargerThan1024 ? 400 : '100%';

  let readingTime = Math.ceil(
    getDurationInSeconds(article.attributes.content, null)
  );

  return (
    <Link
      href={`/article/${article.attributes.slug}`}
      style={{ textDecoration: 'none', color: 'black' }}
    >
      <Flex
        w={isBig ? '90%' : isLarger}
        // mx={5}
        shadow="md"
        borderWidth={1}
        rounded="xl"
        direction="column"
        cursor="pointer"
        mb={10}
      >
        <Box shadow="xl" rounded="xl" overflow="hidden" m={5}>
          <Image
            src={getStrapiMedia(article.attributes.image)}
            alt="poster"
            objectFit="cover"
          />
        </Box>

        <Flex p={5} justifyContent="space-between">
          <Wrap spacing={2}>
            {article?.attributes?.categories?.data.map((item, index) => (
              <Link
                key={index}
                href={`/categories/[category]`}
                as={item.attributes.slug}
                style={{ textDecoration: 'none' }}
              >
                <WrapItem>
                  <Text
                    fontSize={12}
                    fontWeight="900"
                    backgroundColor="#cf0032"
                    color="white"
                    px={2}
                    py={1}
                    rounded="xl"
                  >
                    {capitalizeString(item.attributes.name)}
                  </Text>
                </WrapItem>
              </Link>
            ))}
          </Wrap>
          <Flex minWidth={20}>
            <Text fontWeight="900" fontSize={12} pl={5} noOfLines={1}>
              {readingTime} min read
            </Text>
          </Flex>
        </Flex>
        <Box px={5} pb={5}>
          <Text py={2} fontSize={18} fontWeight="900">
            {article.attributes.title}
          </Text>
          <Text fontSize={14} fontWeight="300">
            {article.attributes.description.length > 100 && isBig
              ? article.attributes.description
              : trucateString(article.attributes.description, 100)}
          </Text>
          <Flex py={5} alignItems="center">
            <Box
              shadow="xl"
              rounded="xl"
              overflow="hidden"
              width={10}
              height={10}
            >
              <Image
                src={getStrapiMedia(
                  article.attributes.author?.data?.attributes?.picture
                )}
                alt="profile"
                width={'50px'}
                height={'50px'}
                objectFit="cover"
                blur={`data:image/svg+xml;base64,${btoa(
                  article?.attributes?.author?.data?.attributes?.picture?.data
                    ?.attributes?.blurhash
                )}`}
              />
            </Box>
            <Text px={3} fontSize={14} fontWeight="300">
              {article.attributes.author?.data?.attributes?.name}
            </Text>
            <Icon my={2} color="#cf0032" as={GoPrimitiveDot} />
            <Text pl={1} pr={1} fontSize={14} fontWeight="300" color="#cf0032">
              {moment(article.attributes.publishedAt).format('MMM DD, YYYY')}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Link>
  );
};

export default NewCard;
