import { gql } from '@apollo/client';

export const GET_HOMEPAGE = gql`
  query GetHomePage {
    homepage {
      data {
        attributes {
          seo {
            metaTitle
            metaDescription
            shareImage {
              data {
                attributes {
                  name
                  url
                  blurhash
                  width
                  height
                }
              }
            }
          }
          hero {
            title
          }
        }
      }
    }
  }
`;
