import { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useQuery, useApolloClient } from '@apollo/client';
import { GET_ARTICLES } from '../apollo/queries/GetArticles';
import { GET_HOMEPAGE } from '../apollo/queries/GetHomepage';
import Articles, { Article, Pagination } from '../components/articles';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { useRouter } from 'next/router';

type Global = {};

type Props = {
  global?: Global;
};

const PAGE_SIZE = 11;

const Blog = ({ global }: Props): JSX.Element => {
  const [posts, setPosts] = useState<Article[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageCount: 1,
  });
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const client = useApolloClient();

  const router = useRouter();

  let { page = null } = router.query;

  const articlesQueryRes = useQuery(GET_ARTICLES, {
    context: { clientName: 'strapi' },
    variables: {
      pagination: {
        page: Number(page) || pagination.page,
        pageSize: PAGE_SIZE,
      },
      sort: ['createdAt:DESC'],
    },
    fetchPolicy: 'cache-first',
  });

  const homepageQueryRes = useQuery(GET_HOMEPAGE, {
    context: { clientName: 'strapi' },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (page && !articlesQueryRes.loading) {
      setPagination({
        page: Number(router.query.page),
        pageCount: articlesQueryRes.data.articles.meta.pagination.pageCount,
      });
    }
  }, [page, articlesQueryRes]);

  useEffect(() => {
    if (!articlesQueryRes.loading) {
      setPosts(articlesQueryRes.data.articles.data);
      setPagination(articlesQueryRes.data.articles.meta.pagination);
      setHasMore(
        pagination.page <
          articlesQueryRes.data.articles.meta.pagination.pageCount + 1
      );
      setIsLoading(false);
    }
  }, [articlesQueryRes]);

  const onPageHandler = async (page: number) => {
    const articlesRes = await client.query({
      query: GET_ARTICLES,
      context: { clientName: 'strapi' },
      variables: {
        pagination: { page: page, pageSize: PAGE_SIZE },
        sort: ['createdAt:DESC'],
      },
    });

    if (!articlesRes.loading) {
      setPosts(articlesRes.data.articles.data);
      setPagination(articlesRes.data.articles.meta.pagination);
      setHasMore(
        articlesRes.data.articles.meta.pagination.page <
          articlesRes.data.articles.meta.pagination.pageCount + 1
      );
      setIsLoading(false);

      router.push({
        query: {
          page: articlesRes.data.articles.meta.pagination.page,
        },
      });
    }
  };

  return (
    <Layout global={global} withSideElements={true} withSubscribe={true}>
      {!homepageQueryRes.loading && (
        <Seo seo={homepageQueryRes.data.homepage.data.attributes.seo} />
      )}
      <Flex direction="column" className="uk-section">
        <div className="uk-container uk-container-large">
          <Articles
            loading={isLoading}
            hasMore={hasMore}
            articles={posts}
            pagination={pagination}
            onClick={(page) => onPageHandler(page)}
          />
        </div>
      </Flex>
    </Layout>
  );
};

export default Blog;
