const latinWordsPerMinute = 265;
const cjkCharactersPerMinute = 500;

const getWordCount = (text, language) => {
  if (language === 'zh') {
    // Estimate 1000 chinese hieroglyphs equal ~700 latin words
    return Math.round(text.replace(/\s\s+/g, '').length * 0.7);
  } else if (language === 'ja') {
    // Estimate 1 japanese hieroglyph is equal to ~1 latin word
    return Math.round(text.replace(/\s\s+/g, '').length);
  } else {
    return text.split(' ').filter(Boolean).length;
  }
};

export const getDurationInSeconds = (content: string, language?: any) => {
  if (['zh', 'ja', 'ko'].includes(language)) {
    return Math.round(
      (content.replace(/\s\s+/g, '').length / cjkCharactersPerMinute) * 60
    );
  } else {
    return getWordCount(content, language) / latinWordsPerMinute;
  }
};
