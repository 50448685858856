import React from 'react';
import Card from './card';
import {
  Flex,
  Box,
  Text,
  Wrap,
  WrapItem,
  IconButton,
  HStack,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export type Article = {
  id: string;
  attributes: {
    title: string;
    excerpt: string;
    slug: string;
    createdAt: string;
    image: {
      url: string;
    };
    author: {
      fullName: string;
      picture: {
        url: string;
      };
    };
    categories: {
      data: {
        id: string;
        attributes: {
          name: string;
          slug: string;
        };
      }[];
    };
  };
};

export type Pagination = {
  page: number;
  pageCount: number;
};

type Props = {
  loading: boolean;
  hasMore: boolean;
  articles: Article[];
  pagination: Pagination;
  onClick: (page: number) => void;
};

const Articles = ({
  loading,
  hasMore,
  articles = [],
  pagination,
  onClick,
}: Props) => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  let isLarger = isLargerThan1024 ? 400 : '100%';

  return (
    <Flex direction="column">
      <Wrap maxW={900}>
        {loading && (
          <Box w={isLarger}>
            <Spinner />
          </Box>
        )}
        {!loading && articles.length > 0 && (
          <>
            {articles.map((article, articleIndex) => {
              return (
                <WrapItem key={articleIndex}>
                  <Card
                    article={article}
                    isBig={articleIndex === 0}
                    key={`article__left__${article.attributes.slug}`}
                  />
                </WrapItem>
              );
            })}
          </>
        )}

        {!loading && articles.length === 0 && (
          <Box w="100%" justifyContent="center" alignItems="center">
            <Text textAlign="center" fontWeight="500">
              We have no content available in this section yet
            </Text>
          </Box>
        )}
      </Wrap>
      {hasMore && (
        <Flex direction="row" alignItems="center" justify="center">
          <HStack>
            <IconButton
              aria-label="Open Menu"
              size="md"
              isDisabled={pagination.page === 1}
              icon={<FaChevronLeft />}
              onClick={() => onClick(pagination.page - 1)}
            />
            <Text fontSize={16} fontWeight="200">
              Page {pagination.page} of {pagination.pageCount}
            </Text>
            <IconButton
              aria-label="Open Menu"
              size="md"
              isDisabled={pagination.page === pagination.pageCount}
              icon={<FaChevronRight />}
              onClick={() => onClick(pagination.page + 1)}
            />
          </HStack>
        </Flex>
      )}
    </Flex>
  );
};

export default Articles;
